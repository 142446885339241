import React, { Component, Fragment } from "react";
import { Modal } from "antd-mobile";
import "./header.scss";
interface OrderHeaderProps {
  title: string;
  share?: boolean;
  history: any;
}
interface OrderHeaderState {
  modal: boolean;
}

class OrderHeader extends Component<OrderHeaderProps, OrderHeaderState> {
  constructor(props: any) {
    super(props);
    this.state = {
      modal: false,
    };
  }
  backHistory = () => {
    if (this.props.title === "收款") {
      this.props.history.push("/historyOrder");
    } else if (this.props.title === "歷史訂單") {
      this.props.history.push("/");
    } else if (this.props.title === "訂單詳情") {
      this.props.history.push("/historyOrder");
    } else {
      this.props.history.goBack();
    }
  };

  showModal = (e: any) => {
    e.preventDefault(); // 修复 Android 上点击穿透
    this.setState({
      modal: true,
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
    });
  };

  share = () => {
    window.location.href = `https://social-plugins.line.me/lineit/share?url=${
      window.location.href
    }`;
  };
  render() {
    return (
      <Fragment>
        <div className="head">
          <svg
            className="my-order-icon"
            aria-hidden="true"
            onClick={this.backHistory}
          >
            <use xlinkHref="#icon-fanhui" />
          </svg>
          <span className="order-header-title">{this.props.title}</span>
          {this.props.share ? (
            <svg
              className="my-icon headicon"
              aria-hidden="true"
              onClick={this.showModal}
            >
              <use xlinkHref="#icon-fenxiang" />
            </svg>
          ) : null}
        </div>
        <div className="grey-line" />
        <Modal
          popup
          visible={this.state.modal}
          onClose={this.onClose}
          animationType="slide-up"
        >
          <div className="line-share-btn" onClick={this.share}>
            分享到Line
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default OrderHeader;
