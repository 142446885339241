import axios from "axios";
import { Toast } from "antd-mobile";
import { getQueryString } from "../util";
import qs from "qs";

console.log(process.env.NODE_ENV);
export const instance = axios.create({
  headers: {
    // "Bra-Id": "12388220577397211136",
    "Buy-Flag": "trendlabstore.cn",
    "Bra-Id": getQueryString("braId"),
    // "Content-Type": "application/x-www-form-urlencoded",
  },
});

instance.interceptors.request.use(function(config: any): any {
  if (!localStorage.getItem("token")) {
    return axios
      .get("/qrcode/meal/tourist/token", {
        headers: {
          Token: localStorage.getItem("oldToken"),
        },
      })
      .then((res: any) => {
        if (res.data.code === 200) {
          localStorage.setItem("token", res.data.data);
          config.headers.Token = res.data.data;
          return config;
        }
      });
  } else {
    config.headers.Token = localStorage.getItem("token");
    return config;
  }
});
instance.interceptors.response.use(
  function(response: any) {
    console.log(response);
    if (response.status === 200) {
      // console.log(response);
      return response.data;
    }
  },
  function(error: any) {
    if (error.response.data.code === 888) {
      localStorage.setItem("oldToken", localStorage.getItem("token") || "");
      localStorage.removeItem("token");
      // Toast.fail("用戶信息已失效,請重新刷新頁面!", 1);
    }
    return Promise.reject({
      data: {
        code: 500,
        message: "網絡錯誤！",
      },
    });
  },
);
const resHandle = (res: any) => {
  if (res.code !== 200) {
    Toast.fail(res.message, 1);
  }
  return res;
};

export class Http {
  static get(url: string, params?: any) {
    return instance
      .get(url, params)
      .then(res => resHandle(res))
      .catch(error => console.error(error));
  }
  static post(url: string, params?: any) {
    return instance
      .post(url, params)
      .then(res => resHandle(res))
      .catch(error => console.error(error));
  }
}

export default axios;
