import { handleActions } from "redux-actions";
import { UPDATE_MENU, UPDATE_MENUDETAIL } from "./types";
import { IndexModel } from "./model";

const initialState: IndexModel = {
  menuList: {},
  menuDetail: [],
};

export const indexReducer = handleActions<IndexModel>(
  {
    [UPDATE_MENU]: (state: any, action: any) => {
      console.log("reducer->state", state);
      console.log("reducer->action:", action);
      return { menuList: action.menuList, menuDetail: state.menuDetail };
    },
    [UPDATE_MENUDETAIL]: (state: any, action: any) => {
      console.log("reducer->state", state);
      console.log("reducer->action:", action);
      return {
        menuList: state.menuList,
        menuDetail: Object.assign(state, {
          [action.key]: { data: action.menuDetail, index: action.index },
        }),
      };
    },
  },
  initialState,
);
