import React, { Component } from "react";
import { Switch, Route } from "react-router";
import { Icon } from "antd-mobile";
import { Toast } from "antd-mobile";
import { routeMap } from "../routes/index";
import Header from "../components/Header";
import OrderHeader from "../components/Orderheader";
import { getBraInfo, getCodeStatus } from "../api";
import { getQueryString } from "../util";
import { Modal } from "antd-mobile";
import "./basiclayout.scss";

function closest(el: any, selector: any) {
  const matchesSelector =
    el.matches ||
    el.webkitMatchesSelector ||
    el.mozMatchesSelector ||
    el.msMatchesSelector;
  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el;
    }
    el = el.parentElement;
  }
  return null;
}
declare const document: any;

export default class BasicLayout extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      storeName: "數據加載中...",
      modal: false,
      wariningText: "",
      tableCode: "",
      // loading: true,
      loading: false,
    };
  }
  componentWillMount = () => {
    if (localStorage.getItem("braId") !== getQueryString("braId")) {
      localStorage.setItem("braId", getQueryString("braId"));
      window.localStorage.removeItem("continueorder");
      window.localStorage.removeItem("lineInof");
      window.localStorage.removeItem("orderlist");
    }
  };

  componentDidMount = async () => {
    if (window.location.href.indexOf("static") !== -1) {
      return;
    }
    const codeStatusRes = await getCodeStatus(getQueryString("codeId"));

    if (codeStatusRes.code === 200 && codeStatusRes.data) {
      this.setState({ tableCode: codeStatusRes.data.tableCode });
      localStorage.setItem("tableCode", codeStatusRes.data.tableCode);
      if (
        codeStatusRes.data.codeStatus === "unbinded" ||
        codeStatusRes.data.codeStatus === "disable"
      ) {
        this.setState({
          wariningText: "二維碼已失效請聯繫商家!",
          modal: true,
        });
      }
    } else {
      this.setState({
        wariningText: "二維碼已失效請聯繫商家!",
        modal: true,
      });
    }
    const storeInfo = await getBraInfo(getQueryString("braId"));
    if (storeInfo.code === 200 && storeInfo.data) {
      this.setState({ storeName: storeInfo.data.braName || "trendlab商户名" });
      // document.title = `”${
      //   storeInfo.data.braName
      // }” 的『碼上點』，最新黑科技的便利點餐體驗`;
      // document
      //   .getElementById("braname")
      //   .setAttribute(
      //     "content",
      //     `”${storeInfo.data.braName}” 的『碼上點』，最新黑科技的便利點餐體驗`,
      //   );
      localStorage.setItem("braName", storeInfo.data.braName);
    } else {
      this.setState({
        wariningText: "門店ID錯誤,請核對二維碼!",
        modal: true,
      });
    }
    this.setState({ loading: false });
  };
  showModal = (e: any) => {
    e.preventDefault(); // 修复 Android 上点击穿透
    this.setState({
      modal: true,
    });
  };
  onClose = () => {
    this.setState({
      modal: false,
    });
  };
  onWrapTouchStart = (e: any) => {
    // fix touch to scroll background page on iOS
    if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
      return;
    }
    const pNode = closest(e.target, ".am-modal-content");
    if (!pNode) {
      e.preventDefault();
    }
  };

  render() {
    return (
      <div className="basic-contain">
        <Switch>
          <Route
            path="/"
            exact
            render={() => <Header title={this.state.storeName} />}
          />
          <Route
            path="/history"
            exact
            render={(props: any) => (
              <OrderHeader
                history={props.history}
                title={this.state.storeName}
              />
            )}
          />
          <Route
            path="/search"
            exact
            render={(props: any) => (
              <OrderHeader history={props.history} title={"搜索"} />
            )}
          />
          <Route
            path="/historyOrder"
            exact
            render={(props: any) => (
              <OrderHeader history={props.history} title={"歷史訂單"} />
            )}
          />
          <Route
            path="/success"
            render={(props: any) => (
              <OrderHeader history={props.history} title={"收款"} />
            )}
          />
          <Route
            path="/call"
            render={(props: any) => (
              <OrderHeader history={props.history} title={"收款"} />
            )}
          />
          <Route
            path="/tipspay"
            render={(props: any) => (
              <OrderHeader history={props.history} title={"收款"} />
            )}
          />
          <Route
            path="/fail"
            exact
            render={(props: any) => (
              <OrderHeader history={props.history} title={"收款"} />
            )}
          />
          <Route
            path="/wait"
            render={(props: any) => (
              <OrderHeader history={props.history} title={"收款"} />
            )}
          />
          <Route
            path="/OrderDetailPay"
            render={(props: any) => (
              <OrderHeader history={props.history} title={"訂單詳情"} share />
            )}
          />
          <Route
            path="/OrderDetailNoPay"
            render={(props: any) => (
              <OrderHeader history={props.history} title={"訂單詳情"} share />
            )}
          />
        </Switch>
        <Switch>
          {routeMap.map(route => (
            <Route
              path={route.path}
              exact={route.exact}
              key={route.name}
              component={route.component}
            />
          ))}
        </Switch>
        <Modal
          visible={this.state.modal}
          transparent
          maskClosable={false}
          onClose={this.onClose}
          title="溫馨提醒"
          footer={[
            {
              text: "我知道了",
              onPress: () => {
                console.log("ok");
                this.onClose();
              },
            },
          ]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div style={{ height: "auto", overflow: "scroll" }}>
            <p>{this.state.wariningText}</p>
          </div>
        </Modal>
        {this.state.loading ? (
          <div className="mask">
            <Icon type="loading" size="lg" />
            <span>數據加載中</span>
          </div>
        ) : null}
      </div>
    );
  }
}
