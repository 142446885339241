import React, { Component, Fragment } from "react";
import { Modal } from "antd-mobile";
import { url } from "../common/urlconfig";
import "./header.scss";

interface HeaderProps {
  title: string;
}
interface HeaderState {
  modal: boolean;
}

class Header extends Component<HeaderProps, HeaderState> {
  constructor(props: any) {
    super(props);
    this.state = {
      modal: false,
    };
  }
  showModal = (e: any) => {
    e.preventDefault(); // 修复 Android 上点击穿透
    this.setState({
      modal: true,
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
    });
  };

  share = () => {
    console.log(process.env.NODE_ENV);
    if (process.env.NODE_ENV === "local") {
      window.location.href = `https://social-plugins.line.me/lineit/share?url=${
        url.testUrl
      }/qrcode/meal/share${encodeURIComponent(window.location.search)}`;
    } else {
      window.location.href = `https://social-plugins.line.me/lineit/share?url=${
        url.proUrl
      }/qrcode/meal/share${encodeURIComponent(window.location.search)}`;
    }
  };
  render() {
    return (
      <Fragment>
        <div className="head">
          <svg className="my-icon headicon" aria-hidden="true">
            {/* <use xlinkHref="#icon-guanbi" /> */}
          </svg>
          <span className="title">{this.props.title}</span>
          <svg
            className="my-icon headicon"
            aria-hidden="true"
            onClick={this.showModal}
          >
            <use xlinkHref="#icon-fenxiang" />
          </svg>
        </div>
        <div className="grey-line" />
        <Modal
          popup
          visible={this.state.modal}
          onClose={this.onClose}
          animationType="slide-up"
        >
          <div className="line-share-btn" onClick={this.share}>
            分享到Line
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default Header;
