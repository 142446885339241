import { Store, createStore, applyMiddleware } from "redux";
import { routerMiddleware } from "react-router-redux";
import { compose } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducer";
import { RootState } from "./initState";
import { History } from "history";

export function configureStore(
  history: History,
  initialState?: RootState,
): Store<RootState> {
  const composeEnhancers =
    (<any>window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middleware = applyMiddleware(
    thunkMiddleware,
    routerMiddleware(history),
  );
  return createStore(
    rootReducer as any,
    initialState as any,
    composeEnhancers(middleware),
  ) as Store<RootState>;
}
