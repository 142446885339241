import React from "react";
import ReactDOM from "react-dom";
import { Fragment } from "react";
import { Provider } from "react-redux";
import createHistory from "history/createBrowserHistory";
import { HashRouter as Router } from "react-router-dom";
import { Route } from "react-router";
import BasicLayout from "./layout/BasicLayout";
import HistoryLayout from "./layout/HistoryLayout";
import { configureStore } from "./store/store";
import "./common/common.css";

const history = createHistory();
const store = configureStore(history);
const App = () => {
  localStorage.removeItem("continueorder");
  localStorage.removeItem("hasOrderId");
  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <BasicLayout />
          {/* <Route path="/" exact component={BasicLayout} />
          <Route path="/history" component={HistoryLayout} /> */}
        </Fragment>
      </Router>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
