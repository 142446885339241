import React from "react";
import { Icon } from "antd-mobile";
import "./index.scss";
function Loading(props: any) {
  if (props.isLoading) {
    if (props.timedOut) {
      return <div>Loader timed out!</div>;
    } else if (props.pastDelay) {
      return (
        <div className="module-loading">
          <Icon type="loading" size="lg" />
        </div>
      );
    } else {
      return null;
    }
  } else if (props.error) {
    console.log(props.error);
    return <div>Error! Component failed to load</div>;
  } else {
    return null;
  }
}

export default Loading;
