import axios, { Http } from "./publicAxios";

// 獲取大分類菜單
export const getMenuCategory: any = () => {
  return Http.get("/qrcode/meal/category");
};
// 獲取菜品菜單
export const getMenuDish: any = (params: any) => {
  return Http.get("/qrcode/meal/order/product", { params });
};

// 获取全部菜品菜单
export const getAllMenu: any = (params: any) => {
  return Http.get("/qrcode/meal/product/all", { params });
};
// 查詢菜品做法
export const getDishOption: any = (params: any) => {
  return Http.get("/qrcode/meal/cooking/way", { params });
};

// 查詢菜品規格
export const getDishSpec: any = (params: any) => {
  return Http.get("/qrcode/meal/specification", { params });
};

// 查詢菜品詳情
export const getDishDetail: any = (params: any) => {
  return Http.get(`/qrcode/meal/product/${params.id}`);
};

// 查詢菜品配料
export const getDishAdd: any = (params: any) => {
  return Http.get("/qrcode/meal/ingredient", { params });
};

// 查询额外费用 seat_cost:餐位费,service_cost:服务费,service_tel:客服电话，alarm_tel:异常告警电话，print_ip:打印地址
export const getAddFee: any = (params: any) => {
  return Http.get("/qrcode/meal/advanced/option", { params });
};

// 提交訂單
export const addOrder: any = (params: any) => {
  return Http.post("/qrcode/meal/order", { ...params });
};

// 呼叫服務員
export const callService: any = (params: any) => {
  return Http.post("/qrcode/meal/serve/call", { ...params });
};

// 查询菜品
export const serachDish: any = (params: any) => {
  return Http.post("/qrcode/meal/product/search", { ...params });
};

// 获取订单列表
export const getHistoryOrderList: any = (params: any) => {
  return Http.get("/qrcode/meal/order/consumer", { params });
};

// 訂單支付
export const postPay: any = (params: any) => {
  return Http.post("/qrcode/meal/order/pay", { ...params });
};

// 模擬line支付
export const postLinePay: any = (params: any) => {
  return Http.post("/qrcode/meal/order/pay/linetest", { ...params });
};

// 模擬支付結果查詢
export const getmodeLinePayRes: any = (params: any) => {
  return Http.get(`/qrcode/meal/order/lineresult/test`, { params });
};

// 訂單詳情
export const getOrderDetail: any = (id: any, params: any) => {
  return Http.get(`/qrcode/meal/order/${id}`, { ...params });
};

// 获取门店信息
export const getBraInfo: any = (id: any) => {
  return Http.get(`/qrcode/meal/store/${id}`);
};
// lineinfo获取
export const getLineInfo: any = (id: any) => {
  return Http.get(`/qrcode/meal/line/profile?code=${id}`);
};
// 查询line支付结果
export const getLinePayRes: any = (params: any) => {
  return Http.get(`/qrcode/meal/order/lineresult`, { params });
};
// 获取游客Token
export const getUserToken: any = (params: any) => {
  return Http.get("/qrcode/meal/tourist/token");
};

// 獲取當前code是否有效
export const getCodeStatus: any = (id: any) => {
  return Http.get(`/qrcode/meal/code/${id}`);
};

// 查詢訂單狀態
export const getOrderStatus: any = (params: any) => {
  return Http.get(`/qrcode/meal/order/receiveOrderStatus`, { params });
};

// 查询商户是否支持服务员现金收款
export const getServicePayWay: any = (params: any) => {
  return Http.get(`/qrcode/meal/advanced/option`, { params });
};
// 地址跳转获取
export const getJumpUrl: any = (params: any) => {
  return Http.get(" /qrcode/meal/codeurl", { params });
};
