import React, { SFC } from "react";
import { RouteProps } from "react-router";
import Loadable from "react-loadable";
import Loading from "../view/Loading/index";

// import Index from "../view/Index/index";
const Index = Loadable({
  loader: () => import("../view/Index/index"),
  loading: Loading,
});

const Order = Loadable({
  loader: () => import("../view/Order/index"),
  loading: Loading,
});
const Success = Loadable({
  loader: () => import("../view/PayResult/Success"),
  loading: Loading,
});
const HistoryOrder = Loadable({
  loader: () => import("../view/HistoryOrder/index"),
  loading: Loading,
});
const Call = Loadable({
  loader: () => import("../view/PayResult/Call"),
  loading: Loading,
});
const OrderDetailPay = Loadable({
  loader: () => import("../view/OrderDetailPay/index"),
  loading: Loading,
});
const Search = Loadable({
  loader: () => import("../view/Serch/index"),
  loading: Loading,
});
const OrderDetailNoPay = Loadable({
  loader: () => import("../view/OrderDetailNoPay/index"),
  loading: Loading,
});
const Wait = Loadable({
  loader: () => import("../view/PayResult/Wait"),
  loading: Loading,
});
const Fail = Loadable({
  loader: () => import("../view/PayResult/Fail"),
  loading: Loading,
});
const PackagePay = Loadable({
  loader: () => import("../view/OutPackagePay/index"),
  loading: Loading,
});
const TipPay = Loadable({
  loader: () => import("../view/PayResult/Tippay"),
  loading: Loading,
});
const Video = Loadable({
  loader: () => import("../view/Static/Video"),
  loading: Loading,
});
const VideoDetail = Loadable({
  loader: () => import("../view/Static/VideoDetail"),
  loading: Loading,
});
const QuestionList = Loadable({
  loader: () => import("../view/Static/Question"),
  loading: Loading,
});
const QuestionDetail = Loadable({
  loader: () => import("../view/Static/QuestionDetail"),
  loading: Loading,
});
const BuyGuide = Loadable({
  loader: () => import("../view/Static/BuyGuide"),
  loading: Loading,
});
const Protocol = Loadable({
  loader: () => import("../view/Static/Protocol"),
  loading: Loading,
});
const UserBooks = Loadable({
  loader: () => import("../view/Static/UserBooks"),
  loading: Loading,
});
const UserBooksDetail = Loadable({
  loader: () => import("../view/Static/BooksDetail"),
  loading: Loading,
});
export const routeConfig = [
  {
    id: "index",
    name: "首页",
    path: "/",
    exact: true,
    component: Index,
  },
  {
    id: "order",
    name: "订单",
    path: "/history",
    exact: true,
    component: Order,
  },
  {
    id: "historyorder",
    name: "历史订单",
    path: "/historyOrder",
    exact: true,
    component: HistoryOrder,
  },
  // {
  //   id: "packagepay",
  //   name: "外卖订单",
  //   path: "/packagepay",
  //   exact: true,
  //   component: PackagePay,
  // },
  {
    id: "success",
    name: "支付成功",
    path: "/success/:orderid",
    exact: true,
    component: Success,
  },
  {
    id: "tipspay",
    name: "柜台支付",
    path: "/tipspay/:orderid",
    exact: true,
    component: TipPay,
  },
  {
    id: "call",
    name: "呼叫服務員",
    path: "/call/:orderid",
    exact: true,
    component: Call,
  },
  {
    id: "wait",
    name: "等待支付结果",
    path: "/wait/:orderid",
    exact: true,
    component: Wait,
  },
  {
    id: "fail",
    name: "支付失败",
    path: "/fail/",
    exact: true,
    component: Fail,
  },
  {
    id: "orderdetailpay",
    name: "订单详情",
    path: "/orderDetailPay/:orderid",
    exact: true,
    component: OrderDetailPay,
  },
  {
    id: "search",
    name: "搜索",
    path: "/search",
    exact: true,
    component: Search,
  },
  {
    id: "orderdetailpay",
    name: "订单详情",
    path: "/orderDetailNoPay/:orderid",
    exact: true,
    component: OrderDetailNoPay,
  },
  {
    id: "video",
    name: "视频教程",
    path: "/static/video",
    exact: true,
    component: Video,
  },
  {
    id: "videodetail",
    name: "视频详情",
    path: "/static/videodetail/:id",
    exact: true,
    component: VideoDetail,
  },
  {
    id: "questionlist",
    name: "问题列表",
    path: "/static/questionlist",
    exact: true,
    component: QuestionList,
  },
  {
    id: "questiondetail",
    name: "问题详情",
    path: "/static/questiondetail/:id",
    exact: true,
    component: QuestionDetail,
  },
  {
    id: "buyguide",
    name: "购买指南",
    path: "/static/buyguide",
    exact: true,
    component: BuyGuide,
  },
  {
    id: "protocol",
    name: "用户协议",
    path: "/static/protocol",
    exact: true,
    component: Protocol,
  },
  {
    id: "userbooks",
    name: "用户手册",
    path: "/static/userbooks",
    exact: true,
    component: UserBooks,
  },
  {
    id: "userdetail",
    name: "用户手册详情",
    path: "/static/userdetail/:id",
    exact: true,
    component: UserBooksDetail,
  },
];

interface Props extends RouteProps {
  name: string;
  key: string;
}

export const routeMap = routeConfig
  .reduce((ary: Props[], cur: any) => {
    return ary.concat(cur.routes || cur);
  }, [])
  .filter(x => x.path && x.path !== "");
