// export function getQueryString(name: any) {
//   var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
//   var r = window.location.search.substr(1).match(reg);
//   if (r != null) {
//     console.log(decodeURI(unescape(r[2])));
//     return decodeURI(unescape(r[2]));
//   }
//   return null;
// }

export function getQueryString(name: any) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(location.search);
  return results == null ? "" : decodeURIComponent(results[1]);
}
